import React from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Input, Label, Form, FormGroup } from "reactstrap";

import CustomTextButton from "components/Custom/Buttons/TextButton";
import { authorizedPost, toggleLoading } from "store/actions";
import { CREATE_AND_SEND_NEW_NFT } from "graphql";

const CreateAndSendNewNft = ({ handleModal, base64img }) => {
    const dispatch = useDispatch();

    // const [base64Img, setBase64Img] = useState("");

    // const [imgPath, setImgPath] = useState("");

    // const imgToBase64 = async (file) => {
    //     let data = await getBase64(file);
    //     setBase64Img(data);
    // };

    const handleCreateNFT = async (data) => {
        console.log(data);
        dispatch(toggleLoading(true));
        handleModal();
        let response = await authorizedPost(CREATE_AND_SEND_NEW_NFT, data);
        if (!response.status) {
            return dispatch(toggleLoading(false));
        }
        dispatch(toggleLoading(false));
    };

    return (
        <div>
            <Formik
                initialValues={{
                    name: "",
                    email: "",
                    corn: "",
                    quantity: 0,
                    location: "",
                    lat_long_value: "",
                    // artwork: imgPath,
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required("Required"),
                    email: Yup.string().max(255).email("Invalid email"),
                    // artwork: Yup.string().required("required"),
                })}
                onSubmit={async (value) => {
                    value.img = base64img;
                    await handleCreateNFT(value);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <div style={{ padding: "1em" }}>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label>Receipt name</Label>
                                <Input
                                    key="name"
                                    name="name"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                />
                                {Boolean(touched.name && errors.name) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.name}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label>Email (optional)</Label>
                                <Input
                                    key="email"
                                    name="email"
                                    type="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                />
                                {Boolean(touched.email && errors.email) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.email}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </FormGroup>

                            {/* <FormGroup>
                                <Label>Artwork</Label>
                                <Input
                                    type="file"
                                    name="artwork"
                                    onChange={(e) => {
                                        setImgPath(e.target.value);
                                        imgToBase64(e.target.files);
                                    }}
                                    onBlur={handleBlur}
                                    accept="image/*"
                                />
                                <FormText color="muted">Select artwork for converting to NFT</FormText>
                                {Boolean(touched.artwork && errors.artwork) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.artwork}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </FormGroup> */}

                            <div>
                                <CustomTextButton
                                    title="Create NFT"
                                    style={{ width: "100%" }}
                                    onClick={handleSubmit}
                                    disabled={values.name === ""}
                                    type="submit"
                                />
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default CreateAndSendNewNft;
