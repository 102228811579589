/*eslint-disable*/
import React from "react";
import { templates } from "./cert_templates";

export class ComponentToPrint extends React.PureComponent {
    render() {
        // eslint-disable-next-line default-case
        switch(this.props.template){
            case "receipt1":{
                return (
                    <div ref={this.props.domEl} style={{ position: 'relative', fontFamily: "'Poppins', sans-serif" }} id="template3">
                        <img src={templates[0]?.img} style={{ width: '45rem' }}></img>
        
                        <div className="info" style={{ position: 'absolute', top: '12.5%', left: '20%', width: '100%' ,display:'flex',flexDirection:'column'}}>
                            <h1 style={{ fontSize: '1rem', fontWeight: "normal", textTransform: "capitalize" }}>{this.props.values.supplierName || 'Supplier Name'}</h1>
                        </div>
                        <div className="info" style={{ position: 'absolute', top: '12.5%', left: '77%', width: '100%' ,display:'flex',flexDirection:'column'}}>
                            <h1 style={{ fontSize: '1rem', fontWeight: "normal", textTransform: "capitalize" }}>{this.props.values.dateAndTime || 'Buyer'}</h1>
                        </div>
                        <div className="info" style={{ position: 'absolute', top: '21.7%', left: '20%', width: '100%' ,display:'flex',flexDirection:'column'}}>
                            <h1 style={{ fontSize: '1rem', fontWeight: "normal", textTransform: "capitalize" }}>{this.props.values.orderNumber || 'Investment Value'}</h1>
                        </div>
                        <div className="info" style={{ position: 'absolute', top: '21.7%', left: '77%', width: '100%' ,display:'flex',flexDirection:'column'}}>
                            <h1 style={{ fontSize: '1rem', fontWeight: "normal", textTransform: "capitalize" }}>{this.props.values.location || 'Credit Period'}</h1>
                        </div>
                        <div className="info" style={{ position: 'absolute', top: '41.7%', left: '20%', width: '100%' ,display:'flex',flexDirection:'column'}}>
                            <h1 style={{ fontSize: '0.8rem', fontWeight: "normal", textTransform: "capitalize" }}>{this.props.values.goodsDescription || 'Goods description'}</h1>
                        </div>
                        <div className="info" style={{ position: 'absolute', top: '41.7%', left: '40%', width: '100%' ,display:'flex',flexDirection:'column'}}>
                            <h1 style={{ fontSize: '0.8rem', fontWeight: "normal", textTransform: "capitalize" }}>{this.props.values.size || 'Size'}</h1>
                        </div>
                        <div className="info" style={{ position: 'absolute', top: '41.7%', left: '46.5%', width: '100%' ,display:'flex',flexDirection:'column'}}>
                            <h1 style={{ fontSize: '0.8rem', fontWeight: "normal", textTransform: "capitalize" }}>{this.props.values.quantity || 'Quantity'}</h1>
                        </div>

                        <div className="info" style={{ position: 'absolute', top: '90.5%', left: '20%', width: '100%' ,display:'flex',flexDirection:'column'}}>
                            <h1 style={{ fontSize: '0.9rem', fontWeight: "normal", textTransform: "capitalize" }}>{this.props.values.receivedBy || 'Expected Profit'}</h1>
                        </div>
                        <div className="info" style={{ position: 'absolute', top: '90.5%', left: '77%', width: '100%' ,display:'flex',flexDirection:'column'}}>
                            <h1 style={{ fontSize: '0.9rem', fontWeight: "normal", textTransform: "capitalize" }}>{this.props.values.checkedBy || 'Return %'}</h1>
                        </div>
        
                    </div>

                );
            }
        }
    }
}