/*eslint-disable*/
import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Card } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { listLandDetails, resetLandDetailsData } from "store/actions";
import "./publicPage.css";
import CustomTextButton from "components/Custom/Buttons/TextButton";
import { getSolExplorerUrl } from "helpers/solExplorer";
import { isAlgo } from "utils";

function PublicNftDetails() {
    const dispatch = useDispatch();
    const { Id } = useParams();

    const { nftLandDetails } = useSelector(({ nfts: { details } }) => ({
        nftLandDetails: details,
    }));

    useEffect(() => {
        dispatch(resetLandDetailsData());
        dispatch(listLandDetails({ id: Id }));
    }, []);

    return (
        <>
            <div className="namebar">
                <a href={`/${isAlgo() ? "?version=algo" : ""}`}>
                    <h3>renai</h3>
                </a>
            </div>

            <div className="page-nav no-margin" style={{ backgroundColor: "rgb(38,39,52)" }}>
                <div className="container">
                    <div className="row">
                        <h2 style={{ color: "white", fontFamily: "El Messiri" }}>Contract Details</h2>
                        <ul>
                            <li>
                                <a href={`/${isAlgo() ? "?version=algo" : ""}`} style={{ color: "white" }}>
                                    <i className="fas fa-home" style={{ color: "white" }} /> home
                                </a>
                            </li>
                            <li style={{ color: "white" }}>
                                <i className="fas fa-angle-double-right" style={{ color: "white" }} />
                                details
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <Grid container justifyContent="center">
                <Grid item lg={6} md={7} sm={8} xs={10}>
                    <div style={{ backgroundColor: "rgb(38,39,52)", color: "white" }}>
                        <div className="explore">
                            <div className="pic3">
                                <img src={nftLandDetails?.art_url} alt="" />
                            </div>
                            <Card style={{ backgroundColor: "#1A1B28", boxShadow: "0px 0px" }}>
                                <div className="description3">
                                    <h3 style={{ textAlign: "center" }}>{nftLandDetails?.name ?? "-"}</h3>

                                    <h4 style={{ textAlign: "center", fontSize: 15 }}>
                                        {nftLandDetails?.description ?? ""}
                                    </h4>

                                    <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>NFT ID : </label>
                                        </div>
                                        <div className="col-sm-8 chip" style={{ fontSize: "14px" }}>
                                            {nftLandDetails?.nft_id ?? "-"}
                                        </div>
                                    </div>

                                    <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>Owner : </label>
                                        </div>
                                        <div className="col-sm-8 chip">{nftLandDetails?.owner_id?.name ?? "-"}</div>
                                    </div>

                                    <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>Status : </label>
                                        </div>
                                        <div className="col-sm-8 chip">{nftLandDetails?.status ?? "-"}</div>
                                    </div>

                                    <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>Documents Collected : </label>
                                        </div>
                                        <div className="col-sm-8 chip">
                                            1. Bill of Lading (BL), 
                                            2. Commercial Invoice cum Packing List,
                                            3. Shipping Bill/ Bill of Export,
                                            4. Proforma Invoice,
                                            5. Export Order or Purchase Order,
                                            6. Certificate of Origin,
                                            7. Bill of Exchange,
                                            8. Credit Insurance of importer,
                                            9. Inspection or Quality Check,
                                            10. Phyto-Sanitary & Fumigation Certificates,
                                            11. Marine Insurance Policy,
                                            12. Mate's Receipt,
                                            13. FEMA Declaration,
                                            14. Let Export Order,
                                            15. Export General Manifest.
                                        </div>
                                    </div>

                                    {/*

                                    <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>Type : </label>
                                        </div>
                                        <div className="col-sm-8 chip">{nftLandDetails?.corn || "-"}</div>
                                    </div>

                                    <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>Quantity : </label>
                                        </div>
                                        <div className="col-sm-8 chip">{nftLandDetails?.quantity ?? "-"}</div>
                                    </div>

                                    <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>Location : </label>
                                        </div>
                                        <div className="col-sm-8 chip">{nftLandDetails?.location || "-"}</div>
                                    </div>

                                    <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>Lat Long Value : </label>
                                        </div>
                                        <div className="col-sm-8 chip">{nftLandDetails?.lat_long_value || "-"}</div>
                                    </div>

                                    <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>Seeding Date : </label>
                                        </div>
                                        <div className="col-sm-8 chip">{nftLandDetails?.seeding_date || "-"}</div>
                                    </div>

                                    <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>Produce Date : </label>
                                        </div>
                                        <div className="col-sm-8 chip">{nftLandDetails?.produce_date || "-"}</div>
                                    </div> */}

                                    {/* <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>Name of the Supplier : </label>
                                        </div>
                                        <div className="col-sm-8 chip">{"RF Exports"}</div>
                                    </div>

                                    <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>Name of the Buyer : </label>
                                        </div>
                                        <div className="col-sm-8 chip">{"Delightful Gourmet"}</div>
                                    </div>

                                    <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>Invoice Amount : </label>
                                        </div>
                                        <div className="col-sm-8 chip">{"18,750"}</div>
                                    </div>

                                    <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>Invested Amount : </label>
                                        </div>
                                        <div className="col-sm-8 chip">{"18,550"}</div>
                                    </div>

                                    <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>Payment Period : </label>
                                        </div>
                                        <div className="col-sm-8 chip">{"30 days"}</div>
                                    </div>

                                    <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>% IRR : </label>
                                        </div>
                                        <div className="col-sm-8 chip">{"12.94%"}</div>
                                    </div>

                                    <div className="row cont-row">
                                        <div className="col-sm-4">
                                            <label>Product Category : </label>
                                        </div>
                                        <div className="col-sm-8 chip">{"Seafood"}</div>
                                    </div> */}

                                    {nftLandDetails.nft_id ? !isAlgo() ? (
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <CustomTextButton
                                                    color="primary"
                                                    title="View On Solana"
                                                    variant="outlined"
                                                    size="small"
                                                    style={{ color: "black" }}
                                                    disabled={!nftLandDetails.nft_id}
                                                    onClick={() => window.open(getSolExplorerUrl(nftLandDetails.nft_id))}
                                                />
                                            </Grid>
                                        </Grid>
                                    ): null : null}

                                    {nftLandDetails.is_for_sale ? (
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <CustomTextButton
                                                    color="primary"
                                                    title={`Buy now (${nftLandDetails?.selling_price} INR)`}
                                                    variant="outlined"
                                                    size="small"
                                                    style={{ color: "black" }}
                                                    disabled={!nftLandDetails.nft_id}
                                                    onClick={() =>
                                                        window.open(
                                                            `https://nft.renai.io/auth/buy-artwork/${nftLandDetails?.nft_id}${isAlgo() ? "?version=algo" : ""}`,
                                                            "_self"
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    ) : null}

                                    {/* <p>
                  <strong>NFT ID : </strong>
                  {nftLandDetails?.nft_id ?? "-"}
                </p>
                <p>
                  <strong>Owner : </strong>
                  {nftLandDetails?.owner_id?.name ?? "-"}
                </p>
                <p>
                  <strong>Status : </strong>
                  {nftLandDetails?.status ?? "-"}
                </p>
                <p>
                  <strong>Crop : </strong>
                  {nftLandDetails?.corn ?? "-"}
                </p>
                <p>
                  <strong>Quantity : </strong>
                  {nftLandDetails?.quantity ?? "-"}
                </p>
                <p>
                  <strong>Location : </strong>
                  {nftLandDetails?.location ?? "-"}
                </p>
                <p>
                  <strong>Lat Long Value : </strong>
                  {nftLandDetails?.location ?? "-"}
                </p>
                <br /> */}
                                </div>
                            </Card>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default PublicNftDetails;
