import { Grid } from "@material-ui/core";
import { marketplaceUrl } from "helpers";
import React from "react";
import { history, isAlgo } from "utils";
import Footer from "./footer";
import Navbar from "./navbar";
import "./style.css";
import { farmList } from "datas/farmList";

const buttonStyle = {
  marginLeft: "18px",
  backgroundColor: "#76fc4e",
  color: "black",
  border: 0,
};

function Landing({setIndex}) {

    const handleExploreButton = (i) => {
      setIndex(i)
      history.push(`/farm-details${isAlgo() ? "?version=algo" : ""}`)
    }
    return (
      <div>
        <Navbar />
        {/* ***************************** Banner Starts Here **********************************/}
        <section
          className="container-fluid banner-container"
          style={{ backgroundColor: "rgb(26,27,40)", color: "white" }}
        >
          <div className="container">
            <div className="row banner-row">
              <div className="col-md-6 banner-txt">
                <h1>
                  Making food trade finance easy, fast and transparent.
                </h1>
                <p>
                  Sell your unpaid invoice to get working capital in 48 hours to scale up your business.
                </p>
                <div className="btn-row row">
                  <button
                    onClick={() => window.open(`${marketplaceUrl}${isAlgo() ? "?version=algo" : ""}`, "_self")}
                    className="btn btn-outline-primary clr"
                    style={{marginLeft:"18px" ,color:"#76fc4e", borderColor:"#76fc4e"}}
                  >
                    View Active Invoices
                  </button>

                  <button
                    onClick={() => history.push(`/register${isAlgo() ? "?version=algo" : ""}`)}
                    className="btn btn-primary clr"
                    style={buttonStyle}
                  >
                    Register now
                  </button>
                </div>
              </div>
              <div className="col-md-6 banner-img">
                <img src="assets/images/undraw_gardening.svg" alt="" />
              </div>
              <br />
              &nbsp;
            </div>
          </div>
        </section>

        <section
          style={{
            paddingTop:"100px",
            paddingBottom:"100px",
            backgroundColor: "rgb(38,39,52)",
            color: "white",
          }}
        >
              <div style={{textAlign:"center",paddingBottom:"80px",fontFamily:"'El Messiri', sans-serif"}}>
              <h2>Farm Listings</h2>
            </div>
          <Grid container justifyContent="center">

            {farmList.map((el, idx) => (
              <Grid item lg={3} md={7} sm={7} xs={10} key={idx}>
                <div className="testimonial" style={{ marginBottom: "50px" }}>
                  <div className="pic">
                    <img src={el.img} alt="" />
                  </div>
                  <div className="description">
                    <h5>{el.name}</h5>
                    <br />
                    <small>
                      <p>
                        {el.description}
                      </p>
                    </small>
                  </div>
                  <h3 className="title"
                    onClick={() => handleExploreButton(idx)}>
                    Explore <i className="fas fa-arrow-right" />
                  </h3>
                </div>
              </Grid>
            ))}
          </Grid>
          <div style={{ textAlign: "center" }}>
            <a href={`/listings${isAlgo() ? "?version=algo" : ""}`} style={{ color: "#76fc4e" }}>
              <br />
              <br />
              View more <i className="fas fa-arrow-right" />
            </a>
          </div>
        </section>

        <Footer />
      </div>
    );
}

export default Landing;
