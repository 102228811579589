export const FETCH_ALL_NFTS = `query ($pageSize: Int, $pageNumber: Int, $status: String) {
    list_all_nfts(page_size: $pageSize, page_number: $pageNumber, status: $status) {
      message
      data {
        _id
        name
        description
        art_url
        nft_id
        status
      }
      hasMore
    }
  }
  `;

export const CREATE_NEW_NFT = `mutation ($name: String!, $corn: String, $quantity: Int, $location: String, $lat_long_value: String, $royalty: Float, $seedingDate: String, $produceDate: String) {
  create_new_nft_data(name: $name, corn: $corn, quantity: $quantity, location: $location, lat_long_value: $lat_long_value, royalty_percentage: $royalty, seeding_date: $seedingDate, produce_date: $produceDate) {
    _id
    message
  }
}

`;

export const FETCH_NFT_DETAILS = `query ($id: String!) {
  list_nft_details(_id: $id) {
    message
    data {
      _id
      name
      description
      update_authority
      art_url
      royalty_percentage
      nft_id
      is_for_sale
      selling_price
      status
      corn
      quantity
      location
      lat_long_value
      seeding_date
      produce_date
      creator_id {
        _id
        name
        email
      }
      owner_id {
        _id
        name
        email
      }
    }
  }
}
`;

export const TRANSFER_NFT_TO_EMAIL = `mutation ($email: String!, $nftId: String!, $name: String) {
  send_nft_to_email(email: $email, nft_id: $nftId, name: $name) {
    message
  }
}
`;

export const TRANSFER_NFT_TO_WALLET = `mutation($wallet: String!, $nftId: String!){
  send_nft_to_wallet(dest_wallet_id: $wallet, nft_id: $nftId){
    message
  }
}`;

export const SEND_FOR_CLOUD_UPLOAD = `mutation ($data: [save_for_cloud_upload_data]) {
  save_for_cloud_upload(data: $data) {
    message
  }
}
`;

export const SELL_NFTS = `mutation ($data: [String]!, $amount: Float!) {
  sell_nfts(nft_data: $data, amount: $amount) {
    message
  }
}
`;

export const DELETE_NFT = `mutation ($nftId: String!) {
  delete_nft(nft_id: $nftId) {
    message
  }
}
`;

export const FETCH_ALL_RECEIVED_NFTS = `query ($pageSize: Int, $pageNumber: Int ) {
  list_all_received_nfts(page_size: $pageSize, page_number: $pageNumber ) {
    message
    data {
      _id
      name
      description
      art_url
      nft_id
      status
    }
    hasMore
  }
}
`;

export const FETCH_ALL_OWN_NFTS = `query ($pageSize: Int, $pageNumber: Int ) {
  list_all_own_nfts(page_size: $pageSize, page_number: $pageNumber ) {
    message
    data {
      _id
      name
      description
      art_url
      nft_id
      status
    }
    hasMore
  }
}
`;

export const FETCH_LAND_DETAILS = `query($id: String!){
  list_land_details(_id: $id){
    message
    data{
      _id
      name
      description
      update_authority
      art_url
      royalty_percentage
      nft_id
      is_for_sale
      selling_price
      status
      corn
      quantity
      location
      lat_long_value
      seeding_date
      produce_date
      creator_id{
        _id
        name
        email
      }
      owner_id{
        _id
        name
        email
      }
    }
  }
}`;

export const CREATE_AND_SEND_NEW_NFT = `mutation ($name: String!, $img: String!, $email: String, $corn: String, $quantity: Int, $location: String, $lat_long_value: String, $royalty: Float, $seedingDate: String, $produceDate: String) {
  create_and_send_new_nft(name: $name, img: $img, email: $email, corn: $corn, quantity: $quantity, location: $location, lat_long_value: $lat_long_value, royalty_percentage: $royalty, seeding_date: $seedingDate, produce_date: $produceDate) {
    _id
    message
  }
}`;