import React from "react";
import { Grid } from "@material-ui/core";
import CustomTextButton from "../Custom/Buttons/TextButton";
import { Card, CardBody } from "reactstrap";

const chip = {
    backgroundColor: "#dcddde",
    borderRadius: "20px",
    padding: "10px",
}

const TxnsCard = ({
    data = [],
    emptyText = "No data available",
    showViewMoreButton = false,
    viewMoreButtonClick,
}) => {
    return (
        <div className="content" style={{ padding: "1em" }}>
            <Grid container spacing={3}>
                {data.length ? (
                    <>
                        {data.map((e, i) => {
                            return (
                                <Grid item xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
                                    <Card
                                        style={{
                                            borderRadius: "20px",
                                            textAlign: "center",
                                            height: "100%",
                                            width: "100%",
                                        }}
                                    >
                                        <CardBody>
                                            <h6>From : </h6>
                                            {e.from?<p style={chip}>{e?.from}</p>:<p style={chip}>-</p>}
                                            <h6>To : </h6>
                                            {e.to?<p style={chip}>{e?.to}</p>:<p style={chip}>-</p>}
                                            <h6>NFT ID :</h6>
                                            {e.nft_id?<p style={chip}>{e?.nft_id}</p>:<p style={chip}>-</p>}
                                            <h6>AMOUNT : {e?.amount} INR</h6>
                                            <h6>type : {e?.type}</h6>
                                            <h6>Status: {e?.status}</h6>
                                        </CardBody>
                                    </Card>
                                </Grid>
                            );
                        })}
                        {/* <div style={{ display: "flex", justifyContent: "center", padding: "1em" }}> */}
                        <Grid container justifyContent="center">
                            {showViewMoreButton ? (
                                <CustomTextButton
                                    color="secondary"
                                    variant="outlined"
                                    title="View More"
                                    onClick={viewMoreButtonClick}
                                    size="small"
                                />
                            ) : null}
                        </Grid>
                        {/* </div> */}
                    </>
                ) : (
                        <Grid container>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Card>
                                    <CardBody>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <h4 style={{ fontSize: "15px", fontWeight: "bold", fontFamily: "inherit" }}>
                                                {emptyText}
                                            </h4>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Grid>
                        </Grid>
                )}
            </Grid>
        </div>
    );
};

export default TxnsCard;
