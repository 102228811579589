import React, { useState } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "react-toastify/dist/ReactToastify.css";

import AdminLayout from "layouts/Admin";
import AuthLayout from "layouts/Auth";

import { history, isAlgo } from "utils";
import Landing from "views/landing";
import PublicNftDetails from "views/public-page";
import FarmListingPage from "views/landing/pages/farmListingPage";
import FarmDetails from "views/landing/pages/farmDetails";
import TermsAndConditions from "views/company-details/terms";
import RefundPolicy from "views/company-details/refunds";
import PrivacyPolicy from "views/company-details/privacy";
import ContactUs from "views/company-details/contact";
import RegisterInvestorForm from "views/register-investor-form";

const Main = () => {
    const [index, setIndex] = useState(null);
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Router history={history}>
                <Switch>
                    <Route path="/app" render={(props) => <AdminLayout {...props} />} />
                    <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                    <Route path="/details/:Id" render={(props) => <PublicNftDetails {...props} />} />
                    <Route path="/listings" render={(props) => <FarmListingPage setIndex={setIndex} {...props} />} />
                    <Route path="/farm-details" render={(props) => <FarmDetails index={index} {...props} />} />
                    <Route path="/terms-and-conditions" render={(props) => <TermsAndConditions {...props} />} />
                    <Route path="/refund-policy" render={(props) => <RefundPolicy {...props} />} />
                    <Route path="/privacy-policy" render={(props) => <PrivacyPolicy {...props} />} />
                    <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
                    <Route path="/register" render={(props) => <RegisterInvestorForm {...props} />} />
                    <Route path="/" render={(props) => <Landing setIndex={setIndex} {...props} />} />
                    <Redirect to={isAlgo() ? "/auth/signin?version=algo" : "/auth/signin"}/>
                </Switch>
            </Router>
        </>
    );
};

export default Main;
