import React from "react";
import { Grid } from "@material-ui/core";
import Navbar from "../navbar";
import { farmList } from "datas/farmList";
import Footer from "../footer";
import { history, isAlgo } from "utils";

function FarmListingPage({setIndex}) {
    const handleExploreButton = (i) => {
        setIndex(i)
        history.push(`/farm-details${isAlgo() ? "?version=algo" : ""}`)
    }
  return (
    <>
      <Navbar />
      <div
        className="page-nav no-margin"
        style={{ backgroundColor: "rgb(38,39,52)" }}
      >
        <div className="container">
          <div className="row">
            <h2 style={{ color: "white", fontFamily: "El Messiri" }}>
            Farm Listings
            </h2>
            <ul>
              <li>
                {" "}
                <a href={`/${isAlgo() ? "?version=algo" : ""}`} style={{ color: "white" }}>
                  <i className="fas fa-home" style={{ color: "white" }} /> home
                </a>
              </li>
              <li style={{ color: "white" }}>
                <i
                  className="fas fa-angle-double-right"
                  style={{ color: "white" }}
                />{" "}
                farms
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        style={{
          paddingTop: "100px",
          paddingBottom: "200px",
          backgroundColor: "rgb(38,39,52)",
          color: "white",
        }}
      >
        <Grid container justifyContent="center">
            {farmList.map((e, i) => {
                return(
          <Grid item lg={3} md={7} sm={7} xs={10} key={i}>
            <div className="testimonial" style={{ marginBottom: "50px" }}>
              <div className="pic">
                <img
                  src={e?.img}
                  alt=""
                />
              </div>
              <div className="description">
                <h5>{e?.name}</h5>
                <br />
                <small>
                  <p>{e?.description}</p>
                </small>
              </div>
                <h3 className="title" onClick={() => handleExploreButton(i)}>
                  Explore <i className="fas fa-arrow-right" />
                </h3>
            </div>
          </Grid>);
          })}
          
        </Grid>
      </div>
      <Footer/>
    </>
  );
}

export default FarmListingPage;
