import React from "react";
import { history, isAlgo } from "utils";
// import "./style.css"

const buttonStyle = {
  backgroundColor:"#76fc4e", 
  color:"black",
  border:0
}

function Navbar() {
  return (
    <>
      {/* ***************************** Head Starts Here **********************************/}
      <div className="head-cover" style={{backgroundColor:"rgb(26,27,40)",color:"white"}}>
        <header id="menu-jk" className="container-fluid">
          <div>
            <div className="row head-ro">
              <div className="col-md-3 logo">
                <a
                  className="d-md-none small-menu"
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <i className="fas d-lg-none  fa-bars" />
                </a>
                <a href={`/${isAlgo() ? "?version=algo" : ""}`}>
                  <img src="assets/logo/Renai_Logo.png" alt="" style={{marginTop: 2}}/>
                {/* <h3 style={{color:"#76fc4e",padding:"10px",fontFamily:"'El Messiri', sans-serif"}}>{process.env.REACT_APP_PROJECT_NAME}</h3> */}
                </a>
              </div>
              <div id="collapseExample" className="col-md-9  nav">
                <ul>
                  <li style={{padding:"23px"}}>
                    <a href={`/${isAlgo() ? "?version=algo" : ""}`}>home</a>
                  </li>
                  <li style={{padding:"23px"}}>
                    <a href={`/listings${isAlgo() ? "?version=algo" : ""}`}>listings</a>
                  </li>
                  {/* <li>
                    <a href="/startups">startups</a>
                  </li> */}
                  {/* <li className="btnll">
                    <a href="https://nft.renai.io/auth/listings" target="_self" rel="noopener noreferrer">
                    <button className="btn btn-sm btn-primary clr" style={buttonStyle}>
                    <strong> View marketplace </strong> 
                    </button>
                    </a>
                  </li> */}
                  <li className="btnll">
                    <button
                     onClick={() => history.push(isAlgo() ? "/auth/login?version=algo" : "/auth/login")}
                     className="btn btn-sm btn-primary clr" style={buttonStyle}>
                    <strong>  Login </strong>
                    </button>
                  </li>
                  {/* <li className="btnll">
                    <a href="/auth/signup">
                    <button className="btn btn-sm btn-primary clr" style={buttonStyle}>
                    <strong> Signup </strong>
                    </button>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}

export default Navbar;
