import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { Input, Label, Form, FormGroup } from "reactstrap";

import CustomTextButton from "components/Custom/Buttons/TextButton";
// import { getBase64 } from "utils";
import { authorizedPost, toggleLoading } from "store/actions";
import { CREATE_NEW_NFT } from "graphql";
import { resetNftsData } from "store/actions";
import { listAllNfts } from "store/actions";
import { FormControl, Grid, MenuItem, Select, TextField } from "@material-ui/core";

const CreateNewNft = ({ handleModal }) => {
    const dispatch = useDispatch();

    // const [base64Im, setBase64Img] = useState("");
    const [selection, setSelection] = useState("");
    const [seedingDate, setSeedingDate] = useState(moment().format("DD-MM-YYYY"));
    const [produceDate, setProduceDate] = useState(moment().format("DD-MM-YYYY"));

    // const [imgPath, setImgPath] = useState("");

    // const imgToBase64 = async (file) => {
    //     let data = await getBase64(file);
    //     setBase64Img(data);
    // };

    const handleCreateNFT = async (data) => {
        dispatch(toggleLoading(true));
        handleModal();
        let response = await authorizedPost(CREATE_NEW_NFT, data);
        if (!response.status) {
            return dispatch(toggleLoading(false));
        }
        dispatch(toggleLoading(false));
        dispatch(resetNftsData());
        return dispatch(listAllNfts());
    };

    return (
        <div>
            <Formik
                initialValues={{
                    name: "",
                    corn: "",
                    quantity: 0,
                    location: "",
                    lat_long_value: "",
                    // description: "",
                    // royalty: 0,
                    // artwork: imgPath,
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required("Name is required"),
                    quantity: Yup.number().min(1),
                    // artwork: Yup.string().required("Artwork is required"),
                })}
                onSubmit={async (value) => {
                    // value.artwork = base64Img;
                    value.corn = selection;
                    value.seedingDate = seedingDate;
                    value.produceDate = produceDate;
                    await handleCreateNFT(value);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <div style={{ padding: "1em" }}>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label>Name of farm</Label>
                                <Input
                                    key="name"
                                    name="name"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                />
                                {Boolean(touched.name && errors.name) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.name}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </FormGroup>

                            <FormGroup>
                                <FormControl fullWidth variant="outlined" color="secondary">
                                    {/* <InputLabel id="demo-simple-select-label">Corn</InputLabel> */}
                                    <Label>Type</Label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selection}
                                        onChange={(event) => {
                                            setSelection(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={"rice"}>Rice</MenuItem>
                                        <MenuItem value={"wheat"}>Wheat</MenuItem>
                                        <MenuItem value={"cashew"}>Cashew</MenuItem>
                                        <MenuItem value={"peanut"}>Peanut</MenuItem>
                                        <MenuItem value={"cocoa"}>Cocoa</MenuItem>
                                        <MenuItem value={"coffee"}>Coffee</MenuItem>
                                        <MenuItem value={"fish"}>Fish</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>

                            <FormGroup>
                                <Label>Quantity (Kg)</Label>
                                <Input
                                    key="quantity"
                                    name="quantity"
                                    type="number"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.quantity}
                                />
                                {Boolean(touched.quantity && errors.quantity) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.quantity}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>Location</Label>
                                <Input
                                    key="location"
                                    name="location"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.location}
                                />
                                {Boolean(touched.location && errors.location) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.location}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label>Lat long value</Label>
                                <Input
                                    key="lat_long_value"
                                    name="lat_long_value"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lat_long_value}
                                />
                                {Boolean(touched.lat_long_value && errors.lat_long_value) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.lat_long_value}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Grid container spacing={1}>
                                    <Grid item lg={6} xs={6}>
                                        <Label>Seeding Date</Label>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            type="date"
                                            defaultValue={moment().format("YYYY-MM-DD")}
                                            onChange={(e) => {
                                                var date = moment(e.target.value);
                                                setSeedingDate(date.format("DD-MM-YYYY"));
                                            }}
                                            inputProps={{
                                                min: moment().format("YYYY-MM-DD"),
                                            }}
                                        />
                                    </Grid>

                                    <Grid item lg={6} xs={6}>
                                        <Label>Produce Date</Label>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            type="date"
                                            defaultValue={moment().add(1, "day").format("YYYY-MM-DD")}
                                            onChange={(e) => {
                                                var date = moment(e.target.value);
                                                setProduceDate(date.format("DD-MM-YYYY"));
                                            }}
                                            inputProps={{
                                                min: moment().add(1, "day").format("YYYY-MM-DD"),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </FormGroup>

                            {/* <FormGroup>
                                <Label>Description</Label>
                                <Input
                                    key="description"
                                    name="description"
                                    type="textarea"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description}
                                />
                                {Boolean(touched.description && errors.description) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.description}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </FormGroup> */}

                            {/* <FormGroup>
                                <Label>Artwork</Label>
                                <Input
                                    type="file"
                                    name="artwork"
                                    onChange={(e) => {
                                        setImgPath(e.target.value);
                                        imgToBase64(e.target.files);
                                    }}
                                    onBlur={handleBlur}
                                    accept="image/*"
                                />
                                <FormText color="muted">Select artwork for converting to NFT</FormText>
                                {Boolean(touched.artwork && errors.artwork) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.artwork}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </FormGroup> */}

                            <div>
                                <CustomTextButton
                                    title="Create NFT"
                                    style={{ width: "100%" }}
                                    onClick={handleSubmit}
                                    disabled={values.name === ""}
                                    type="submit"
                                />
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default CreateNewNft;
