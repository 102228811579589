import React, { useRef, useEffect } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
//import { useSelector } from "react-redux";

import { Container } from "reactstrap";

// import Footer from "components/Footer/Footer.js";

import { authRoutes } from "routes.js";
import AuthNavbar from "components/Navbars/AuthNavbar";
import { isAlgo } from "utils";
//import Loader from "components/Loader";

var ps;

function AuthLayout() {
    const mainPanel = useRef();
    const location = useLocation();

    // const { loading } = useSelector(({ loading }) => ({
    //     loading,
    // }));

    useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle("perfect-scrollbar-on");
        }
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                document.body.classList.toggle("perfect-scrollbar-on");
            }
        };
    }, []);

    useEffect(() => {
        mainPanel.current.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [location]);

    return (
        <div className="wrapper" style={{ backgroundColor: "#262734" }}>
            <div ref={mainPanel}>
                <AuthNavbar fluid />
                <Container>
                    {/* <Loader visible={loading} /> */}
                    <Switch>
                        {authRoutes.map((prop, key) => {
                            if (prop.layout === "/auth") {
                                return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
                            } else {
                                return <Redirect to={isAlgo() ? "/auth/signin?version=algo" : "/auth/signin"} key={key} />;
                            }
                        })}
                        <Redirect from="*" to={isAlgo() ? "/auth/signin?version=algo" : "/auth/signin"}/>
                    </Switch>
                </Container>
                {/* <Footer fluid /> */}
            </div>
        </div>
    );
}

export default AuthLayout;
