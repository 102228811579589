import React, { useState } from "react";
import {
    Box,
    Card,
    Container,
    FormControl,
    Grid,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Select,
    Button,
    CircularProgress,
    Slide,
} from "@material-ui/core";
import { Email, Person, Phone } from "@material-ui/icons";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { countryISDCodes } from "datas/isdCodes";
import { toast } from "react-toastify";
import { Formik } from "formik";
// import NavBar from "views/landing/navBar";
import * as Yup from "yup";
import Navbar from "views/landing/navbar";
import { isAlgo } from "utils";
// import { unAuthorizedCommonApiCall } from "store/actions";
// import { SCHEDULE_DEMO_ALERT } from "graphql";

const errMsgStyle = {
    display: "block",
    color: "red",
    fontSize: 13,
}

export default function RegisterInvestorForm() {
    const [isd, setIsd] = useState("91");
    const [isLoading, setIsLoading] = useState(false);

    const initialValues = {
        name: "",
        email: "",
        phone: "",
        website: "",
        note: "",
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(255).required("Name is required"),
        email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
        phone: Yup.string().max(255).required("Phone number is required"),
    })

    const handleSubmit = async (values) => {
        setIsLoading(true);
        values.isdCode = `+${isd}`;
        console.log(values);

        const SPREADSHEET_ID = process.env.REACT_APP_GL_SPREADSHEET_ID;
        const SHEET_ID = process.env.REACT_APP_GL_SHEET_ID.toString();
        const CLIENT_EMAIL = process.env.REACT_APP_GL_CLIENT_EMAIL;
        const PRIVATE_KEY = process.env.REACT_APP_GL_PRIVATE_KEY;

        const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

        try {
            await doc.useServiceAccountAuth({
                client_email: CLIENT_EMAIL,
                private_key: PRIVATE_KEY,
            });
            // loads document properties and worksheets
            await doc.loadInfo();

            const sheet = doc.sheetsById[SHEET_ID];
            const rows = await sheet.getRows();

            const filtered = rows.filter((e) => e.Email === values.email);

            if (!filtered.length) {
                let date = new Date()
                // await unAuthorizedCommonApiCall(
                //     SCHEDULE_DEMO_ALERT, 
                //     {
                //         mobile : values.phone,
                //         need: values.note,
                //         ...values
                //     }
                // )
                await sheet.addRow({
                    Date: date.toLocaleString(),
                    Email: values.email,
                    Name: values.name,
                    ISD: values.isdCode,
                    Phone: values.phone,
                    Website: values.website,
                    Note: values.note,
                });
            }

            setIsLoading(false);
            return toast.success("Thanks for showing your interest. Our team will contact you shortly.", {autoClose: false, position: "bottom-center"});
        } catch (e) {
            console.log("error", e);
            setIsLoading(false);
            return toast.error("Failed to register");
        }
    }

    const handleIsdChange = (e) => {
        setIsd(e.target.value);
    };

    // const alertScheduleApiCall = (data) => {
    //     unAuthorizedCommonApiCall(SCHEDULE_DEMO_ALERT, data)
    // }

    return (
        <>
            <Navbar />
            <Box sx={{ width: "100%", color: "#fff", bgcolor: "rgb(26,27,40)" }}>
                <Container>
                    <Box
                        alignItems="center"
                        justifyContent="center"
                        sx={{ minHeight: "100vh", p: "40px 10px", pt: "80px" }}
                    >
                        <Grid
                            container
                            spacing={5}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item lg={6} md={6} sm={8} xs={12}>
                                <Slide direction="down" in={true} mountOnEnter unmountOnExit>
                                    <Box>
                                        <h2 style={{ color: "#fff" }}>
                                            <b>Register here</b>
                                        </h2>
                                        {/* <h5 style={{ color: "#ccc", paddingRight: "4rem" }}>
                                            Get a demo where we will help you understand how you can wow your team.
                                        </h5> */}
                                        <img width="90%" src="assets/images/undraw_forms.svg" alt="" />
                                    </Box>
                                </Slide>
                            </Grid>
                            <Grid item lg={5} md={5} sm={8} xs={12}>
                                <Box>
                                    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                                        <Card>
                                            <Formik
                                                initialValues={initialValues}
                                                validationSchema={validationSchema}
                                                onSubmit={handleSubmit}
                                            >
                                                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                                                    <form onSubmit={handleSubmit}>
                                                        <Box sx={{ p: 4 }}>
                                                            <Box mb={2}>
                                                                <OutlinedInput
                                                                    fullWidth
                                                                    size="small"
                                                                    autoComplete="on"
                                                                    type="text"
                                                                    name="name"
                                                                    placeholder="Your name"
                                                                    defaultValue={values.name}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    required={true}
                                                                    startAdornment={
                                                                        <InputAdornment position="start">
                                                                            <Person />
                                                                        </InputAdornment>
                                                                    }
                                                                />
                                                                {Boolean(touched.name && errors.name) ? (
                                                                    <div style={errMsgStyle}>
                                                                        {errors.name}
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Box>
                                                            <Box mb={2}>
                                                                <OutlinedInput
                                                                    fullWidth
                                                                    size="small"
                                                                    autoComplete="on"
                                                                    type="email"
                                                                    name="email"
                                                                    placeholder="Email"
                                                                    defaultValue={values.email}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    required={true}
                                                                    startAdornment={
                                                                        <InputAdornment position="start">
                                                                            <Email />
                                                                        </InputAdornment>
                                                                    }
                                                                />
                                                                {Boolean(touched.email && errors.email) ? (
                                                                    <div style={errMsgStyle}>
                                                                        {errors.email}
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Box>
                                                            <Box mb={2} display="flex" flexDirection="row">
                                                                <FormControl sx={{ width: "30%" }} >
                                                                    <Select value={isd} onChange={handleIsdChange} variant="outlined">
                                                                        {countryISDCodes.map((countries, index) => (
                                                                            <MenuItem value={countries?.code} key={index}>
                                                                                {countries?.iso}- {countries?.country}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                                <Box width="80%">
                                                                    <OutlinedInput
                                                                        fullWidth
                                                                        size="small"
                                                                        autoComplete="on"
                                                                        type="number"
                                                                        name="phone"
                                                                        placeholder="Phone number"
                                                                        defaultValue={values.phone}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        required={true}
                                                                        startAdornment={
                                                                            <InputAdornment position="start">
                                                                                <Phone /> +{isd}
                                                                            </InputAdornment>
                                                                        }
                                                                    />
                                                                    {Boolean(touched.phone && errors.phone) ? (
                                                                        <div style={errMsgStyle}>
                                                                            {errors.phone}
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                            {/* <Box mb={2}>
                                                                <OutlinedInput
                                                                    fullWidth
                                                                    size="small"
                                                                    type="text"
                                                                    name="website"
                                                                    placeholder="Your website"
                                                                    defaultValue={values.website}
                                                                    onChange={handleChange}
                                                                    startAdornment={
                                                                        <InputAdornment position="start">
                                                                            <Public />
                                                                        </InputAdornment>
                                                                    }
                                                                />
                                                            </Box>
                                                            <Box mb={2}>
                                                                <OutlinedInput
                                                                    fullWidth
                                                                    size="small"
                                                                    type="text"
                                                                    name="note"
                                                                    placeholder="What is your need?"
                                                                    defaultValue={values.note}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box> */}
                                                            <Button
                                                                fullWidth
                                                                type="submit"
                                                                variant="contained"
                                                                style={{ backgroundColor: "#2bba00", color: "#fff" }}
                                                            >
                                                                {isLoading ?
                                                                    <CircularProgress size={23} color="inherit" /> :
                                                                    <b>Register now</b>
                                                                }
                                                            </Button>
                                                        </Box>
                                                    </form>
                                                )}
                                            </Formik>
                                        </Card>
                                    </Slide>
                                    <Box mt={1}>
                                        Already registered? {" "}
                                        <a href={isAlgo() ? "/auth/signin?version=algo" : "/auth/signin"}>
                                            sign in here
                                        </a>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    );
}

