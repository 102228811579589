import React from "react";
import { Grid } from "@material-ui/core";

import Footer from "views/landing/footer";
import Navbar from "views/landing/navbar";

const RefundPolicy = () => {
    return (
        <div>
            <Navbar />
            <section
                style={{
                    backgroundColor: "rgb(38,39,52)",
                    height: "100vh",
                    color: "white",
                }}
            >
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="row section-title">
                        <h2>Refund Policy</h2>
                    </div>
                </div>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item lg={6} md={8} xs={10}>
                        <p>
                            We do not provide a refund for the purchase. We might consider for any refund, on case by case
                            basis and this will be at the sole discretion of Metabasu Technologies Private Limited. If you
                            are approved, then your refund will be processed, and a credit will automatically be applied to
                            your credit card or original method of payment, within a certain amount of days. If you haven’t
                            received a refund yet, first check your bank account again. Then contact your credit card
                            company, it may take some time before your refund is officially posted. Next contact your bank.
                            There is often some processing time before a refund is posted. If you’ve done all of this and
                            you still have not received your refund yet, please contact us at <b>renaipay.ai@gmail.com</b>.
                        </p>
                    </Grid>
                </Grid>
            </section>
            <Footer />
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css"
            />
        </div>
    );
};

export default RefundPolicy;
