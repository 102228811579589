import {
    LIST_ALL_NFTS,
    LIST_LAND_DETAILS,
    LIST_NFT_DETAILS,
    RESET_LAND_DETAILS_DATA,
    RESET_NFT_DETAILS_DATA,
    RESET_NFT_LIST_DATA,
    RESET_SELLING_ITEMS,
    SET_SELLING_ITEMS,
} from "../types";

const INITIAL_STATE = {
    list: [],
    showViewMore: false,
    pageNumber: 1,
    pageSize: 10,
    details: {},
    sellingItems: [],
};

const nftReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_ALL_NFTS:
            return {
                ...state,
                pageSize: 10,
                ...payload,
            };

        case LIST_NFT_DETAILS:
            return {
                ...state,
                details: payload,
            };

            case LIST_LAND_DETAILS:
            return {
                ...state,
                details: payload,
            };
            
        case RESET_NFT_LIST_DATA:
            return INITIAL_STATE;

        case RESET_NFT_DETAILS_DATA:
            return {
                ...state,
                details: INITIAL_STATE.details,
            };

        case RESET_LAND_DETAILS_DATA:
            return {
                ...state,
                details: INITIAL_STATE.details,
            };

        case SET_SELLING_ITEMS:
            return {
                ...state,
                sellingItems: payload,
            };

        case RESET_SELLING_ITEMS:
            return {
                ...state,
                sellingItems: [],
            };

        default:
            return state;
    }
};

export default nftReducer;
