/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card } from "reactstrap";
import { FormControl, Grid, MenuItem, Select, Switch } from "@material-ui/core";

import CustomTable from "components/Custom/Tables/CustomTable";
import CustomIconButton from "components/Custom/Buttons/IconButton";
import CustomModal from "components/Custom/Modal";
import CreateNewNft from "./CreateNewNft";

import CustomTextButton from "components/Custom/Buttons/TextButton";
import { history, isAlgo } from "utils";
import { toast } from "react-toastify";
import NftCard from "components/Cards/NftCard";
import { listAllNfts, resetNftsData, toggleViewMode } from "store/actions";
import { showFunqCredits, resetFunqCredits } from "store/actions";
import { getCreatorStatus, resetCreatorStatus } from "store/actions";

const NftList = () => {
    const dispatch = useDispatch();
    const [addNewModal, setAddNewModal] = useState(false);
    // const [infoModal, setInfoModal] = useState(false);
    const [status, setStatus] = useState("");

    const { nftsData, showViewMore, funqCreditsData, creatorStatus } = useSelector(
        ({ nfts: { list, showViewMore }, users: { funqCredits, creatorStatus } }) => ({
            nftsData: list,
            showViewMore,
            funqCreditsData: funqCredits,
            creatorStatus,
        })
    );

    const { viewMode } = useSelector(({ txns: { isCardView } }) => ({
        viewMode: isCardView,
    }));

    useEffect(() => {
        dispatch(resetNftsData());
        fetchMoreNfts();
        dispatch(resetFunqCredits());
        dispatch(showFunqCredits());
        dispatch(resetCreatorStatus());
        dispatch(getCreatorStatus());
    }, [status]);

    const fetchMoreNfts = () => {
        dispatch(listAllNfts({ status: status }));
    };

    const handleAddNewModalClose = () => {
        setAddNewModal(false);
    };

    // const handleInfoModalClose = () => {
    //     setInfoModal(!infoModal);
    // };

    const handleAddnewButton = () => {
        if (funqCreditsData?.credits === 0) {
            toast.error("You have no renai coins");
        } else {
            setAddNewModal(true);
        }
    };

    // const handleBulkUploadButton = () => {
    //     if (funqCreditsData?.credits === 0) {
    //         toast.error("You have no renai coins");
    //     } else {
    //         history.push("/app/upload");
    //     }
    // };

    const handleChange = (event) => {
        dispatch(toggleViewMode(event.target.checked));
    };

    return (
        <>
            <Container fluid>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <div style={{ padding: "1em" }}>
                                <Grid container justifyContent="space-between">
                                    <Grid item xl={3} lg={4} md={5} sm={12} xs={12}>
                                        <Grid container spacing={1} justifyContent="flex-start">
                                            {creatorStatus ? (
                                                <Grid item>
                                                    <CustomIconButton
                                                        id="add-new-btn"
                                                        title="Add New"
                                                        icon="fas fa-plus"
                                                        onClick={handleAddnewButton}
                                                    />
                                                </Grid>
                                            ) : null}
                                            {/* <Grid item>
                                                <CustomIconButton
                                                    id="upload-btn"
                                                    title="Bulk Upload"
                                                    icon="fas fa-upload"
                                                    onClick={handleBulkUploadButton}
                                                />
                                            </Grid> */}
                                            <Grid item>
                                                <CustomTextButton
                                                    title="Sell Contracts"
                                                    onClick={() => history.push(isAlgo() ? "/app/sell-contracts?version=algo" : "/app/sell-contracts")}
                                                />
                                            </Grid>
                                            <Grid item style={{ padding: "15px", fontSize: "18px" }}>
                                                Card view
                                                <Switch
                                                    checked={viewMode}
                                                    onChange={handleChange}
                                                    inputProps={{ "aria-label": "controlled" }}
                                                    color="primary"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Grid container spacing={1} justifyContent="flex-end" style={{ padding: "15px" }}>
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                style={{ maxWidth: "200px", minWidth: "120px" }}
                                            >
                                                <Select
                                                    value={status}
                                                    onChange={(event) => {
                                                        setStatus(event.target.value);
                                                    }}
                                                    displayEmpty
                                                >
                                                    <MenuItem value="">
                                                        <em>All</em>
                                                    </MenuItem>
                                                    <MenuItem value="pending">Pending</MenuItem>
                                                    <MenuItem value="created">Created</MenuItem>
                                                    <MenuItem value="sold">Sold</MenuItem>
                                                    <MenuItem value="sales">Listed</MenuItem>
                                                    <MenuItem value="transferred">Transferred</MenuItem>
                                                    <MenuItem value="received">Received</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container fluid>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {!viewMode ? (
                            <CustomTable
                                title="Contracts Summary"
                                columns={[
                                    {
                                        title: "Actions",
                                        render: (rowData) => (
                                            <CustomTextButton
                                                title="open"
                                                otherProps={{ size: "sm" }}
                                                onClick={() => history.push(isAlgo() ? `/app/nft/${rowData._id}?version=algo` : `/app/nft/${rowData._id}`)}
                                            />
                                        ),
                                    },
                                    { title: "Name", field: "name" },
                                    // { title: "Description", field: "description" },
                                    { title: "NFT ID", field: "nft_id" },
                                    {
                                        title: "Contract",
                                        field: "art_url",
                                        render: (rowData) => <img src={rowData?.art_url} width={40} height={40} />,
                                    },
                                    { title: "Status", field: "status" },
                                ]}
                                data={nftsData}
                                showViewMoreButton={showViewMore}
                                viewMoreButtonClick={() => fetchMoreNfts()}
                            />
                        ) : (
                            <NftCard
                                data={nftsData}
                                showViewMoreButton={showViewMore}
                                viewMoreButtonClick={() => fetchMoreNfts()}
                            />
                        )}
                    </Grid>
                </Grid>
                <CustomModal
                    content={<CreateNewNft handleModal={handleAddNewModalClose} />}
                    visible={addNewModal}
                    title="contract details"
                    handleClose={handleAddNewModalClose}
                />
                {/* <CustomModal
                    content={
                        <>
                            The artwork will be converted to NFT within 24 hours. You will get an email notification when
                            it's converted
                        </>
                    }
                    visible={infoModal}
                    title="NFT uploaded"
                    handleClose={handleInfoModalClose}
                /> */}
            </Container>
        </>
    );
};

export default NftList;
