import jwtDecode from "jwt-decode";

export const isLoggedIn = (token) => {
    if (token !== null) {
        let decoded = jwtDecode(token);

        if (decoded) {
            const now = Date.now().valueOf() / 1000;
            if (typeof decoded.exp !== "undefined" && decoded.exp < now) {
                return false;
            }
            storeAccountType(decoded.accountType);
            return true;
        } else {
            return false;
        }
    }

    return false;
};

const storeAccountType = (accountType) => {
    if (accountType && accountType !== "") {
        localStorage.setItem("accountType", accountType);
    }
    else {
        localStorage.setItem("accountType", "");
    }
};