/*eslint-disable*/
import React from "react";
import { isAlgo } from "utils";

function Footer() {
    return (
        <>
            <div className="copy">
                <div className="container">
                    <a href={`/${isAlgo() ? "?version=algo" : ""}`}>2022 © All Rights Reserved | {process.env.REACT_APP_PROJECT_NAME}</a>
                    <span>
                        <a href={`terms-and-conditions${isAlgo() ? "?version=algo" : ""}`} style={{ padding: "5px" }}>
                        {" "}Terms And Conditions{" "}
                        </a>|
                        <a href={`privacy-policy${isAlgo() ? "?version=algo" : ""}`} style={{ padding: "5px" }}>
                        {" "}Privacy Policy{" "}
                        </a>|
                        <a href={`refund-policy${isAlgo() ? "?version=algo" : ""}`} style={{ padding: "5px" }}>
                        {" "}Refund Policy{" "}
                        </a>|
                        <a href={`contact-us${isAlgo() ? "?version=algo" : ""}`} style={{ padding: "5px" }}>
                        {" "}Contact Us{" "}
                        </a>
                        {/* <a href="/">
                            <i className="fab fa-telegram" />
                        </a>
                        <a href="/">
                            <i className="fab fa-linkedin" />
                        </a>
                        <a href="/">
                            <i className="fab fa-whatsapp" />
                        </a>
                        <a href="/">
                            <i className="fab fa-facebook-f" />
                        </a> */}
                    </span>
                </div>
            </div>
        </>
    );
}

export default Footer;
