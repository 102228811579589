// /*eslint-disable*/
import React, { useRef, useState } from 'react'
import "./homepage.css"
import * as htmlToImage from 'html-to-image';
// import Papa from "papaparse";
// import ReactToPrint from 'react-to-print';
import { ComponentToPrint } from './componentToPrint';
import { templates } from './cert_templates';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CustomModal from 'components/Custom/Modal';
import CreateAndSendNewNft from './CreateAndSendNewNft';
// import MOCK_DATA from "./../files/MOCK_DATA.csv";

const Popup = (props) => {
    return (props.trigger) ? (
        <div className="popup">
            <div className="popup-box">
                <button onClick={() => props.setpop(false)} >x</button>
                <h3 style={{ fontWeight: '400' }}>{props.count === props.total ? "Completed" : "Processing..."}</h3>
                <div className="count">
                    {props.count} / {props.total}
                </div>
            </div>
        </div>
    ) : null;
}
function Receipts() {
    const date = new Date();
    let time = date.getTime();

    const [pop, setpop] = useState(false);
    const [name, setname] = useState('');
    const [values, setValues] = useState({
        supplierName: "",
        dateAndTime: "",
        OrderNumber: "",
        location: "",
        receivedBy: "",
        checkedBy: "",
    });
    const [fileName, setFileName] = useState(`receipt${time}`);
    const [heading, setheading] = useState('');
    const [desc, setdesc] = useState('');
    const [author, setauthor] = useState('');
    const [logo, setlogo] = useState('');
    const [template, settemplate] = useState('receipt1');
    const [saveAs, setSaveAs] = useState('jpeg');
    const [theme, setTheme] = useState("dark");
    const [count, setCount] = useState(0);
    const [data, setData] = useState([]);
    const [base64img, setBase64img] = useState("");
    const [addNewModal, setAddNewModal] = useState(false);

    const componentRef = useRef();
    const domEl = useRef(null);

    // const ref = useRef<HTMLDivElement>(null);
    // const toggleTheme =() => {
    //     if(theme === "dark") {
    //         setTheme("light");
    //     }
    //     else {
    //         setTheme("dark");
    //     }
    // }

    const { funqCreditsData } = useSelector(
        ({ users: { funqCredits } }) => ({
            funqCreditsData: funqCredits,
        })
    );

    const handleAddnewButton = () => {
        if (funqCreditsData?.credits === 0) {
            toast.error("You have no renai coins");
        } else {
            setAddNewModal(true);
        }
        downloadImage();
    };

    const downloadImage = async () => {
        var dataUrl = '';
        if (saveAs === "jpeg") {
            dataUrl = await htmlToImage.toJpeg(domEl.current, { pixelRatio: 2 });
        }
        else if (saveAs === "png") {
            dataUrl = await htmlToImage.toPng(domEl.current, { pixelRatio: 2 });
        }
        else if (saveAs === "svg") {
            dataUrl = await htmlToImage.toSvg(domEl.current, { pixelRatio: 2 });
        }

        setBase64img(dataUrl);
        // const link = document.createElement('a');
        // link.download = `${fileName}.${saveAs}`;
        // link.href = dataUrl;
        // link.click();
    }

    // const handleFileChange = (e) => {
    //     setError("");
    //     setData([]);

    //     if (e.target.files.length) {
    //         const inputFile = e.target.files[0];

    //         const fileExtension = inputFile?.type.split("/")[1];
    //         if (!allowedExtensions.includes(fileExtension)) {
    //             setError("Please input a csv file");
    //             return;
    //         }

    //         setFile(inputFile);
    //     }
    // };

    // const handleParse = () => {
    //     if (!file) return setError("Enter a valid file");

    //     const reader = new FileReader();

    //     reader.onload = async ({ target }) => {
    //         const csv = Papa.parse(target.result, { header: true });
    //         const parsedData = csv?.data;
    //         parsedData?.pop();

    //         if (!parsedData[0]?.Name || !parsedData[0]?.FileName) {
    //             setError("No required column found");
    //             return;
    //         } else {
    //             setData(parsedData);
    //         }
    //     };
    //     reader.readAsText(file);
    // };

    return (
        <div className="main" style={{ background: (theme === "dark") ? "linear-gradient(#000, #222831, #000)" : "white" }}>
            <Popup trigger={pop} setpop={setpop} count={count} total={data?.length}>
            </Popup>
            <div className="maincontainer">
                <div className="leftmost">
                    <h3 style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Templates</h3>

                    <div className={`templates ${template === 'receipt1' ? "active" : ""}`} onClick={() => settemplate(templates[0]?.name)} >
                        <img src={templates[0]?.img} alt="" />
                    </div>
                </div>
                <div className="mid">
                    <div>
                        <ComponentToPrint domEl={domEl} ref={componentRef} name={name} values={values} heading={heading} desc={desc} author={author} logo={logo} template={template} />
                    </div>
                </div>
                <div className="right">
                    <div className="form">
                        <>
                            <div className="input-box">
                                <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Supplier Name</span>
                                <input id="nameInput" name="nameInput" type="text" placeholder="Enter supplier Name" onChange={e => { setValues({ ...values, supplierName: e.target.value }) }} />
                            </div>
                            <div className="input-box">
                                <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Investment Value</span>
                                <input id="nameInput" name="nameInput" type="text" placeholder="Enter Investment Value" onChange={e => { setValues({ ...values, orderNumber: e.target.value }) }} />
                            </div>
                            <div className="input-box">
                                <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Buyer</span>
                                <input id="nameInput" name="nameInput" type="text" placeholder="Enter Buyer" onChange={e => { setValues({ ...values, dateAndTime: e.target.value }) }} />
                            </div>
                            <div className="input-box">
                                <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Credit Period</span>
                                <input id="nameInput" name="nameInput" type="text" placeholder="Enter Credit Period" onChange={e => { setValues({ ...values, location: e.target.value }) }} />
                            </div>
                            <div className="input-box">
                                <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Goods Description</span>
                                <input id="nameInput" name="nameInput" type="text" placeholder="Enter goods description" onChange={e => { setValues({ ...values, goodsDescription: e.target.value }) }} />
                            </div>
                            <div className="input-box">
                                <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Size</span>
                                <input id="nameInput" name="nameInput" type="text" placeholder="Enter size" onChange={e => { setValues({ ...values, size: e.target.value }) }} />
                            </div>
                            <div className="input-box">
                                <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Quantity</span>
                                <input id="nameInput" name="nameInput" type="text" placeholder="Enter quantity" onChange={e => { setValues({ ...values, quantity: e.target.value }) }} />
                            </div>
                            <div className="input-box">
                                <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Expected Profit</span>
                                <input id="nameInput" name="nameInput" type="text" placeholder="Enter Expected Profit" onChange={e => { setValues({ ...values, receivedBy: e.target.value }) }} />
                            </div>
                            <div className="input-box">
                                <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Return %</span>
                                <input id="nameInput" name="nameInput" type="text" placeholder="Enter Return %" onChange={e => { setValues({ ...values, checkedBy: e.target.value }) }} />
                            </div>
                            {/* <hr style={{ margin: "5px 0" }} />
                            <div className="input-box">
                                <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>File Name</span>
                                <input id="fileName" name="fileName" type="text" placeholder="Enter File Name" onChange={e => { setFileName(e.target.value) }} />
                            </div> */}
                        </>
                        {/* <div className="input-box">
                            <span className="details" style={{ color: (theme === "dark") ? "white" : "rgb(28, 39, 43)" }}>Save as</span>
                            <select id="save-as" onChange={(e) => setSaveAs(e.target.value)}>
                                <option value="jpeg">jpeg</option>
                                <option value="png">png</option>
                                <option value="svg">svg</option>
                            </select>
                        </div> */}
                        <button id="jpegButton" name="jpegButton" className="generate" type="button" onClick={handleAddnewButton}>Create and send to email</button>
                        {/* <button id="jpegButton" name="jpegButton" className="generate" type="button" onClick={downloadImage}>Download</button> */}
                    </div>
                </div>
            </div>
            <CustomModal
                content={<CreateAndSendNewNft handleModal={() => setAddNewModal(false)} base64img={base64img} />}
                visible={addNewModal}
                title="details"
                handleClose={() => setAddNewModal(false)}
            />
        </div>
    )
}

export default Receipts
