export const FETCH_USER_PROFILE = `query{
  view_profile{
    message
    data {
      _id
      name
      email
      img
      is_creator
      acc_balance
      funq_credits
      wallet_address
    }
  }
}`;

export const UPDATE_USER_PROFILE =`mutation($name: String!){
  update_profile(name: $name){
    message
  }
}`;

export const FETCH_HOMEPAGE_DATA = `query{
  homepage_reports{
    message
    data {
      total_own_nfts
      total_sold_nfts
    }
  }
}`;

export const REDEEM_WALLET_AMOUNT = `mutation ($destWalletId: String!, $amount: Float!) {
  redeem_wallet_amount(dest_wallet_id: $destWalletId, amount: $amount) {
    message
  }
}
`;

export const FETCH_FUNQ_CREDITS = `query{
  show_funq_credits{
    message
    data{
      credits
    }
  }
}`;

export const FETCH_CREATOR_STATUS = `query{
  get_creator_status{
    status
  }
}`;