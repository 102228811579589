import React, { useEffect } from 'react'
import { Card, Grid } from '@material-ui/core'
import { farmList } from 'datas/farmList'
import { history } from 'utils';
import { isAlgo } from 'utils';

function FarmDetails({index}) {
    useEffect(() => {
        if(index===null){
            history.push(isAlgo() ? "/listings?version=algo" : "/listings")
        }
// eslint-disable-next-line
    },[])
  return (
    <>
    <div
        className="page-nav no-margin"
        style={{ backgroundColor: "rgb(38,39,52)" }}
      >
        <div className="container">
          <div className="row">
            <h2 style={{ color: "white", fontFamily: "El Messiri" }}>
            Farm Listings
            </h2>
            <ul>
              <li>
                {" "}
                <a href={isAlgo() ? "/?version=algo" : "/"} style={{ color: "white" }}>
                  <i className="fas fa-home" style={{ color: "white" }} /> home
                </a>
              </li>
              <li style={{ color: "white" }}>
                <a href={isAlgo() ? "/listings/?version=algo" : "/listings"} style={{ color: "white" }}>
                  <i
                    className="fas fa-angle-double-right"
                    style={{ color: "white" }}
                  />{" "}
                  farms
                </a>
              </li>
              <li style={{ color: "white" }}>
                <i
                  className="fas fa-angle-double-right"
                  style={{ color: "white" }}
                />{" "}
                explore
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Grid container justifyContent="center">
        <Grid item lg={6} md={7} sm={8} xs={10}>
          <div style={{ backgroundColor: "rgb(38,39,52)", color: "white" }}>
            <div className="explore">
              <div className="pic3" style={{ borderRadius:"50%", backgroundColor:"#1A1B28" }}>
                <img src={farmList[index]?.img} alt="" />
              </div>
              <Card style={{ backgroundColor: "#1A1B28",boxShadow:"0px 0px" }}>
                <div className="description3">
                  <h3 style={{ textAlign: "center" }}>
                  {farmList[index]?.name}
                  </h3>

                  <div className="row cont-row">
                    <div className="col-sm-4">
                      <label>Description : </label>
                    </div>
                    <div className="col-sm-8 chip" style={{fontSize:"14px"}}>
                    {farmList[index]?.description}
                    </div>
                  </div>

                  <div className="row cont-row">
                    <div className="col-sm-4">
                      <label>Crops : </label>
                    </div>
                    <div className="col-sm-8 chip">
                    {farmList[index]?.crops}
                    </div>
                  </div>

                  <div className="row cont-row">
                    <div className="col-sm-4">
                      <label>Area : </label>
                    </div>
                    <div className="col-sm-8 chip">
                    {farmList[index]?.area}
                    </div>
                  </div>

                  <div className="row cont-row">
                    <div className="col-sm-4">
                      <label>Location : </label>
                    </div>
                    <div className="col-sm-8 chip">
                    {farmList[index]?.location}
                    </div>
                  </div>

                  <div className="row cont-row">
                    <div className="col-sm-4">
                      <label>Amount Raising : </label>
                    </div>
                    <div className="col-sm-8 chip">
                    {farmList[index]?.amountRaising}
                    </div>
                  </div>

                </div>
              </Card>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default FarmDetails