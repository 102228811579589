export const farmList = [
    {
        id:"0001",
        img:"https://static.licdn.com/aero-v1/sc/h/cs8pjfgyw96g44ln9r7tct85f",
        name:"R F Exports",
        description:"One of the Best Export Factories in India. Twenty Two years in the export of seafood products in India, RF Exports has forged ahead to be recognized as the leader.",
        crops:"-",
        area:"-",
        location:"Eramalloor, Alappuzha District, Kerala.",
        amountRaising:"-",
    },
    {
        id:"0002",
        img:"https://static.vecteezy.com/system/resources/previews/017/210/706/original/m-s-letter-logo-design-with-swoosh-design-concept-free-vector.jpg",
        name:"M/s Delightful Gourmet Private Limited (Licious)",
        description:"It is a leading food company providing high-quality, fresh meat and seafood products with a commitment to quality and convenience.",
        crops:"-",
        area:"-",
        location:"Baby Marine Intl, Thoppumpady, Kochi - 682 005.",
        amountRaising:"-",
    },
    {
        id:"0003",
        img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSi1CuQ1ZNwT_2b7SxI0-N5t2k4rR9px87Rd8BZSFsUeYppNk-41c1iaO55YwizRS0ASaY&usqp=CAU",
        name:"Geospice Pvt Ltd",
        description:"Geospice Pvt Ltd: A premier exporter of spices, sourcing and delivering premium-quality products.",
        crops:"-",
        area:"-",
        location:"Ernakulam, kerala",
        amountRaising:"-",
    },
]